/* .react-datepicker-wrapper, .react-datepicker__input-container { display: block !important; } 
.MyClass > .react-datepicker-wrapper .react-datepicker__input-container input { width: 30% } */

.react-datepicker-popper {
    z-index: 3;
  }
.picker-error .react-datepicker__input-container input {
  border: 1px solid red;
}

input[name="quotationDateTime"] {
  width: 100%;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}
